#unitHeader{
    color: white;
    text-align: center;
    border: 1px solid white;
}

#priceHeader{
    color: white;
    text-align: center;
    border: 1px solid white;
}

#unitTableData{
    color: white;
    border: 1px solid white;
    padding: 10px;
    width: 550px;
    @media(max-width: 800px){
        font-size: 18px;
    }
}

#priceTableData{
    color: white;
    text-align: right;
    border: 1px solid white;
    width: 170px;
    padding-right: 10px;
    @media(max-width: 800px){
        font-size: 18px;
    }
}

/* ORDER MODAL */
#orderModal{
    color: white
}

.modal-height{
    width: 1250px;
    @media(max-width: 800px){
        height: 1200px;
    }
}

.modal-width{
    
}

#modalHeader{
    border: 2px solid white;
    background-color: #013A6B;
}

#modalTitle{
    margin-left: 10px;
    padding: 0;
    font-size: 32px;
}

#modalBody{
    border: 2px solid white;
    background-color: #004E95;
}

/* ORDER MODAL TABLE */

#quantityInput{
    width: 60px;
    text-align: center;
    background: none;
    border: none;
    color: white;
    border-bottom: 1px solid white;
    @media(max-width: 800px){
        font-size: 14px;
        width: 30px;
    }
}

#quantityInput::placeholder{
    color: white;
    opacity: 1;
}

#tdOrderQuantity{
    font-size: 24px;
    border: 1px solid white;
    text-align: center;
    @media(max-width: 800px){
        width: 30px;
    }
}

/* User/Address Info */

#stateDropdownContainer{
    height: 50px;
}

#stateDropdownButton{
    width: 240px;
    border: none;
    background: none;
    text-align: left;
    padding: 10px;
    color: white;
    @media(max-width: 800px){
        width: 100px;
    }
}

#stateDropdownOptions{
    
}

.stateDropDownIte{
    color: white
}

#table{
    
}


#dropButton {
    width: 100%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    background-color: #013A6B;
    color: white;
    border: 1px solid white;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
}

/* Entire dropdown menu */
.dropdown-menu {
    width: 100%;
    background-color: #013A6B !important; 
    border: 1px solid white !important; 
    
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important; /* Ensure visibility on mobile */
    @media(max-width: 900px){
        width: 200%;
    }
}

/* Individual dropdown items */
#dropItem {
    color: white !important;
    background-color: #013A6B !important;
    margin: 0 !important;
    outline: none !important;
    border-radius: 0 !important;
    border: none !important; /* Add white border between items */
    overflow: visible !important; /* Ensure text visibility on mobile */
    word-wrap: break-word !important; /* Ensure text wraps properly */
    display: block !important; /* Ensure full background visibility */
    width: 100% !important; /* Ensure background color covers the entire width */
}

/* Remove any potential spacing/gaps between items */
.dropdown-item {
    padding: 10px 15px !important;
    border: none !important;
    border-bottom: 1px solid white !important; /* Ensure border is visible */
    background-color: #013A6B !important; /* Ensure background color is always visible */
    display: block !important; /* Make sure each item takes full width */
    width: 100% !important;
}

/* Remove border from last item */
.dropdown-item:last-child {
    border-bottom: none !important;
}

